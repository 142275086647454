.offerx_left_nav {
    width: 15rem;
    background-color: var(--lt-bg-white);
    position: fixed;
    height: 100%;
    z-index: 10;
    padding: 16px;
    box-shadow: 0 0.5px 3.6px rgb(0 0 0 / 10%);
    border-right: 0;
    overflow-y: auto;
}
.offerx_nav_links .nav-link {
    padding: 8px;
    border-radius: 6px;
    margin: 5px 0;
    color: var(--lt-text-primary);
    font-size: var(--fs-12);
    width: 100%;
}
.offerx_nav_links .nav-link.active,
.offerx_nav_links .nav-link:hover {
    background-color: var(--lt-bg-lighter-alt);
    color: var(--lt-text-primary-alt);
}
.offerx_nav_links .hover-link.active span,
.offerx_nav_links .hover-link:hover span {
    font-weight: 600;
}
.offerx_nav_links .hover-link.active button,
.offerx_nav_links .hover-link:hover button {
    font-weight: 600;
}
.offerx-nav-bottom-link:hover {
    background-color: inherit !important;
}
.offerx_nav_links .nav-link i {
    margin-right: 10px;
}
.offerx-logo-link {
    text-decoration: none;
    color: var(--lt-text-primary);
    padding: 8px;
}
.offerx-nav-bottom-link {
    border-top: 1px solid var(--divider-default);
    padding-top: 20px !important;
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.offerx-admin-header {
    padding: 10px;
    display: flex;
    border-bottom: 1px solid var(--divider-default);
    background-color: var(--lt-bg-white);
}
.dropdown-item:disabled {
    opacity: 0.3;
}
.link-warps {
    white-space: pre-wrap;
    width: -webkit-fill-available;
    word-break: break-all;
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.link-warps {
    white-space: pre-wrap;
    width: -webkit-fill-available;
    word-break: break-all;
}

.page-content {
    overflow-y: auto !important;
    overflow-x: hidden;
    padding: 1em 1em 1em 16em;
    height: 100vh;
}
h1 {
    font-size: var(--fs-20) !important;
    font-weight: 700 !important;
    margin-top: 10px;
    margin-bottom: 20px;
}
.date-range {
    width: 150px;
    margin-top: 8px;
}
.form-select {
    border-radius: 6px;
}
.lt-dashboard-text {
    color: var(--lt-text-blue);
}

.lt-dashboard-icon {
    position: absolute;
    right: 9px;
    top: 5px;
}
.chart-view .form-check-input:focus {
    box-shadow: none;
}
.chart-view .form-check-input {
    border-radius: 0px;
    font-size: 24px;
}
#inlineCheckbox1 {
    border: 2px solid var(--lt-text-primary-alt);
}
#inlineCheckbox2 {
    border: 2px solid #2bc04c;
}
#inlineCheckbox3 {
    border: 2px solid #d83b01;
}
#inlineCheckbox4 {
    border: 2px solid #f0c916;
}
.chart-view .form-check-label {
    font-size: var(--fs-14) !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    margin-top: 8px;
    margin-right: 30px;
}
.table-responsive th {
    vertical-align: top;
}
.table-responsive td {
    vertical-align: middle;
    font-size: var(--fs-14);
}
/* .table-responsive td button{
    font-size: var(--fs-14); 
} */

.table > :not(:first-child) {
    border-top: 1px;
}
.form-date-width {
    width: 16em;
}
.lt-text-yellow {
    color: var(--lt-alert-bg-warning-fill);
}
.hover-icons i {
    visibility: hidden;
    padding: 0 5px;
}
.hover-icons a:last-child i {
    padding-right: 0;
}
.hover-icons .lt-flag-icon i {
    visibility: visible !important;
    color: var(--lt-text-error);
}
.table-hover > tbody > tr:hover .hover-icons i {
    visibility: visible;
}
.lt_Offerx_Suspend_accordion .accordion-button {
    padding-left: 0;
    padding-right: 0;
    font-weight: var(--fw-600);
}
.lt_Offerx_Suspend_accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: var(--lt-text-primary);
}
.lt_Offerx_Suspend_accordion .accordion-body {
    padding-top: 0;
}
.avatar-lg {
    width: 72px !important;
    height: 72px !important;
}
.avatar-md {
    width: 56px !important;
    height: 56px !important;
}
.lt-border,
.lt-border:hover {
    border: 1px solid var(--border-default) !important;
}
.lt-dropdown-border a.btn {
    border: 1px solid var(--border-default) !important;
    padding: 0.375rem 0.75rem;
    border-color: var(--border-default);
}
.lt-dropdown-border > .lt-dropdown-menu > a.btn.btn-secondary {
    border-color: var(--border-default);
}
.positive-relative {
    position: relative;
}
.lt_Offerx_accordion .accordion-button:not(.collapsed),
.lt_Offerx_accordion .accordion-button h2 {
    background-color: transparent;
    color: var(--lt-text-primary);
}
.lt-reply-li {
    padding: 0px 0px 15px;
}
.lt-badge {
    background-color: var(--divider-default);
    color: var(--lt-text-secondary);
}
.lt-review-li:last-child {
    border-style: none;
}
.card .lt-text-success {
    color: var(--lt-text-success) !important;
}
.card .lt-text-error {
    color: var(--lt-text-error) !important;
}

a {
    text-decoration: none;
}

.site-logo {
    height: 22px;
}
.accordion-item .accordion-button::after {
    content: '\F282';
    font-family: 'bootstrap-icons';
    color: var(--lt-text-primary);
    background-image: none;
}

.accordion-button {
    color: var(--lt-text-dark-theme);
}

.navbar-toggler .bi-list {
    color: var(--lt-text-primary);
}
.star-icon {
    margin-bottom: 10px;
}
.accordion-header .lt-snack-bg-error {
    background-color: var(--lt-snack-bg-error) !important;
}
.feedback-popup > tbody,
.feedback-popup > tbody > td,
.feedback-popup > tfoot,
.feedback-popup > thead > tr > th,
.feedback-popup > thead,
.feedback-popup > tr {
    height: 30px;
}
.feedback-popup > thead > tr {
    height: 30px;
}
.ox-dashboard-title,
.border-bottom {
    border-bottom: 1px solid var(--divider-default);
}
.company-page-container {
    padding: 0 10px;
}
/* .lt_suspendStatus {
    width: auto;
} */
.lt_suspendStatus .form-select.lt-filter-box,
.lt_salesOwner .form-select.lt-filter-box,
.lt_status .form-select.lt-filter-box,
.lt_documentType .form-select.lt-filter-box {
    cursor: pointer;
    padding: 3px 33px 3px 10px !important;
}
@media (max-width: 1920px) {
    .lt_avatarUrl {
        width: 45px;
    }
    .lt_rating {
        width: 180px;
    }
    .lt_suspendStatus,
    .lt_actions {
        width: 130px;
    }
    .lt_suspensionDecision {
        width: 120px;
    }
    .lt_reportedDate {
        width: 140px;
    }
    .lt_joinStatus {
        width: 130px;
    }
    .lt_dateAdded {
        width: 180px;
    }
    .lt_permissionTypeList {
        width: 200px;
    }
    /* .lt_{
        width: 140px;
    } */
}
@media (max-width: 1366px) {
    .lt_avatarUrl {
        width: 45px;
    }
    .lt_rating {
        width: 160px;
    }
    .lt_suspendStatus,
    .lt_actions {
        width: 110px;
    }
    .lt_suspensionDecision {
        width: 110px;
    }
    .lt_reportedDate {
        width: 140px;
    }
    .lt_dateAdded {
        width: 140px;
    }
    .lt_permissionTypeList {
        width: 200px;
    }
}
@media (min-width: 992px) {
    .offerx_left_nav {
        visibility: visible !important;
        transform: inherit;
    }
    .offerx-admin-header {
        display: none;
    }
}
@media (max-width: 992px) {
    .page-content {
        padding-left: 1em !important;
        overflow-y: inherit !important;
        overflow-x: inherit !important;
    }
    .offcanvas {
        z-index: 9999;
    }
    .lt_Offerx_accordion .accordion-button:not(.collapsed),
    .lt_Offerx_accordion .accordion-button h2 {
        line-height: 1.5;
        margin-bottom: 10px;
    }
    .accordion-item .accordion-body .form-check.d-flex {
        display: block !important;
    }
    .accordion-header .lt-snack-bg-error {
        background-color: var(--lt-snack-bg-error) !important;
    }
}
@media (max-width: 1920px) {
    .lt_avatarUrl {
        width: 45px;
    }
    .lt_rating {
        width: 180px;
    }
    .lt_suspendStatus,
    .lt_actions {
        width: 230px;
    }
    .lt_suspensionDecision {
        width: 150px;
    }
    .lt_reportedDate {
        width: 150px;
    }
}
@media (max-width: 1366px) {
    .lt_avatarUrl {
        width: 45px;
    }
    .lt_rating {
        width: 160px;
    }
    .lt_suspendStatus,
    .lt_actions {
        width: 120px;
    }
    .lt_suspensionDecision {
        width: 110px;
    }
    .lt_reportedDate {
        width: 140px;
    }
}

@media (min-width: 992px) {
    .offerx_left_nav {
        visibility: visible !important;
        transform: inherit;
    }
    .offerx-admin-header {
        display: none;
    }
}
@media (max-width: 992px) {
    .page-content {
        padding-left: 1em !important;
    }
    .offcanvas {
        z-index: 9999;
    }
    .lt_Offerx_accordion .accordion-button:not(.collapsed),
    .lt_Offerx_accordion .accordion-button h2 {
        line-height: 1.5;
        margin-bottom: 10px;
    }
    .accordion-item .accordion-body .form-check.d-flex {
        display: block !important;
    }
    .border-start {
        border: 0px !important;
    }
}
@media (min-width: 375px) {
    .star-icon .bi {
        margin-right: -2px;
    }
}
.legal-doc-table tbody > tr {
    height: 60px !important;
}
.legal-doc-table tbody > tr:hover {
    background-color: aliceblue !important;
}
