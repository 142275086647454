.rating
  position: relative
  background-image: url("../../../assets/icons/star.svg")

  &__progress
    height: inherit
    background-image: url("../../../assets/icons/star-fill.svg")

  &__buttons
    position: absolute
    top: 0
    left: 0
    display: flex
    width: inherit
    height: inherit