[id*="wrapperdigio"]
  top: 1.75rem !important
  left: 52% !important
  width: 94% !important
  max-width: 1200px
  min-height: 94vh
  transform: translateX(-52%)


.modal-dialog--reject
  width: 94%
  max-width: 1200px

  .btn-close
    top: -10px

  .modal-body
    margin-top: -5px