.react-pdf
  &__Document
    max-height: 800px
    border: 1px solid #D2D2D2
      radius: 4px
    overflow-y: auto

  &__Page
    box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.1), 0 1.6px 3.6px rgba(0, 0, 0, 0.13)

    & + &
      margin-top: 16px

    &__canvas
      width: 100% !important
      height: auto !important

    &__textContent
      display: none

  @at-root .doc-full-size &
    &__Document
      max-height: none


.docx-container
  max-height: 800px
  padding: 0 16px
  border: 1px solid #D2D2D2
    radius: 4px
  overflow-y: auto

  @at-root &
    max-height: none
