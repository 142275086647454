.offer-no-result-img
  width: 88px


.offer-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 438px


.lt-tooltiptext
  &--lg
    width: 510px

  &--right
    right: auto
    left: 24px

  &--bottom
    top: calc(100% + 4px)
    right: 0 !important



