.header-avatar
  display: inline-block
  width: 20px
  height: 20px
  margin: 4px 0
  vertical-align: top
  position: relative
  left: -5px


.avatar
  border-radius: 50%
  object-fit: cover
  object-position: center

  &--xs
    width: 32px !important
    height: 32px !important

  &--sm
    width: 64px
    height: 64px

  &--md
    width: 120px
    height: 120px

  &--lg
    width: 160px
    height: 160px
