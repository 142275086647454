header + .container
  padding-bottom: 32px


.btn:not(.btn-lg)
  padding:
    top: 0.281rem
    bottom: 0.281rem
  font-size: 0.875rem


.form-control
  padding:
    top: 0.269rem
    bottom: 0.269rem
  font-size: 0.875rem


.form-select
  padding:
    top: 0.281rem
    bottom: 0.281rem
  font-size: 0.875rem


.op-card-box
  color: #A19F9D

  &:hover, &.active
    > div
      position: relative
      bottom: -2px

    color: #323130

    .fill
      fill: #323130

  &.active
    background: #eaf6ff
    border: 3px solid #c7e9f4
    cursor: default
    pointer-events: none

  > svg
    margin: auto


.dropdown-menu
  .scrolls-area
    padding-right: 16px


[aria-labelledby="MyProfile"]
  right: 0 !important
  left: auto !important