.react-datepicker
  border: none
  box-shadow: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13)

  &__input-container
    input
      font-size: 14px
      width: 100%
      height: 32px
      padding: 0 36px 0 12px
      border: 1px solid #d2d2d2
        radius: 4px
      outline: none
      background:
        repeat: no-repeat
        position: right 12px center
        image: url("../../icons/calendar.svg")

      &:focus
        border-color: #86b7fe

  &__triangle
    display: none

  &__header
    background-color: #fff
    padding: 12px 0 0
    border: none

  &__day-names
    padding: 0 8px

  &__day
    width: 2rem
    height: 2rem
    border-radius: 50% !important
    font-size: 0.95em
    line-height: 2rem

    &--disabled
      pointer-events: none

    &--keyboard-selected
      color: inherit !important
      background-color: transparent

      &:hover
        color: #fff !important

    &--selected
      color: #fff !important
      background-color: #0096FF

    &--in-range, &--in-selecting-range
      background-color: #E0E0E0 !important
      color: inherit !important

    &--range-start, &--range-end, &--selecting-range-start, &--selecting-range-end
      color: #fff !important
      background-color: #0096FF !important

    &--outside-month
      opacity: 0

  &__day-name
    width: 2rem
    font-size: 0.95em
    color: #A19F9D

  &__week
    display: flex


.react-datepicker-popper
  z-index: 2


.react-datepicker-header
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 0.95em
  font-weight: 500

  &--range
    margin-top: 36px
    padding-top: 12px
    border-top: 1px solid #E0E0E0

  &__range
    position: absolute
    z-index: 2
    top: 0
    right: -100%
    left: 0
    display: flex
    align-items: center
    height: 48px
    padding-left: 14px
    font-size: 0.93rem
    font-weight: 500

  &__arrow
    margin: 0 6px
    font-size: 1.4rem
    color: gray

  &__btn
    display: flex
    width: 30px
    height: 30px
    padding-top: 6px
    border-radius: 50%
    box-shadow: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13)
    background-color: #fff

    &[disabled]
      opacity: 0.3
      cursor: default

    &--previous
      margin-left: 10px
      padding-left: 12px

    &--next
      margin-right: 10px
      padding-left: 18px

    .react-datepicker__navigation-icon
      &:before
        border-color: #86b7fe

  &__date
    padding-bottom: 2px


.react-datepicker-error
  .react-datepicker__input-container input
    border-color: #dc3545
