@charset "utf-8";

html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--pop-font-family) !important;
    background-color: var(--lt-bg-white) !important;
    color: var(--lt-text-dark-theme);
}

p {
    margin-bottom: 0;
}

header {
    background-color: var(--lt-bg-white);
    box-shadow: var(--lt-shadow-sm);
    position: sticky;
    top: 0;
    z-index: 1;
}

table {
    border-collapse: separate;
    border-spacing: 0px 5px;
    table-layout: auto;
}

td {
    background-color: #f5f8fb;
    position: relative;
}

table {
    table-layout: fixed;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    height: 40px;
}
thead > tr:hover {
    box-shadow: none;
}
thead > tr {
    height: 70px;
}
.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: rgb(0 0 0 / 0%) !important;
    background-color: #eaf6ff !important;
}

.table > :not(caption) > * > * {
    border-bottom-width: 0;
}

tr:hover {
    -moz-box-shadow: 0 0 5px 0 rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 14%);
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 14%);
}

tr:hover td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

tr:hover td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

tr:hover td:first-child::before {
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 35%);
    border: 1px solid #000;
}

tr:hover td:after {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.dropend .dropdown-toggle::after {
    border: 0;
}

.dropdown-toggle::after {
    display: none;
}

.form-control:focus {
    box-shadow: none !important;
}

.form-select:focus {
    box-shadow: none !important;
}

.swal2-title {
    font-size: 18px !important;
    font-weight: 700 !important;
}

.swal2-html-container {
    margin-top: 10px !important;
    font-size: 14px !important;
}

.swal2-icon.swal2-warning {
    border-color: #ffc107 !important;
    color: #ffc107 !important;
}

.swal2-actions {
    margin-top: 25px !important;
    text-align: center !important;
    padding: 0 15px !important;
    width: 100% !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
    width: 47% !important;
    margin-bottom: 0 !important;
    height: 35px !important;
    font-size: var(--fs-14) !important;
}

.swal2-styled.swal2-cancel {
    background: #fff !important;
    border: 1px solid var(--lt-text-primary-alt) !important;
    color: var(--lt-text-primary-alt) !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.swal2-styled.swal2-cancel:hover {
    background: var(--lt-text-primary-alt) !important;
    color: var(--lt-text-white-color) !important;
}

.nav-tabs {
    border-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid var(--lt-text-primary-default);
    font-weight: var(--fw-600);
    color: var(--lt-text-primary-alt);
}
.nav-tabs .nav-link.active {
    font-weight: var(--fw-700);
}
.nav-tabs .nav-link {
    margin-bottom: -2px;
    border: 0;
    border-bottom: 2px solid transparent;
    color: var(--lt-text-secondary);
}

.badge {
    border-radius: 2px;
    padding: 5px 6px 2px;
    font-size: var(--fs-16);
    font-weight: var(--fw-600);
}

.badge-info {
    background-color: var(--divider-default);
    color: var(--lt-text-secondary);
    line-height: 1.2;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 8px;
}

.active .badge-info {
    background-color: #b1dfff;
    color: var(--lt-text-primary-default);
    line-height: 1.2;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 8px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border: none;
    border-bottom: 2px solid var(--lt-text-primary-default);
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: rgba(123, 123, 123, 0.22);
    border-radius: 50px;
}

*::-webkit-scrollbar-thumb {
    background: #a7a7a7;
    border-radius: 50px;
}

*::-webkit-scrollbar-thumb:hover {
    background: #5a5a5a;
    border-radius: 50px;
}

*::-moz-scrollbar {
    width: 6px;
}

*::-moz-scrollbar-track {
    background: rgba(123, 123, 123, 0.22);
}

*::-moz-scrollbar-thumb {
    background: #a7a7a7;
}

*::-moz-scrollbar-thumb:hover {
    background: #5a5a5a;
}

/* Font weights */
.fs-5 {
    font-size: var(--fs-5) !important;
}

.fs-10 {
    font-size: var(--fs-10) !important;
}

.fs-12 {
    font-size: var(--fs-12) !important;
}

.fs-14 {
    font-size: var(--fs-14) !important;
}

.fs-16 {
    font-size: var(--fs-16) !important;
}

.fs-18 {
    font-size: var(--fs-18) !important;
}

.fs-20 {
    font-size: var(--fs-20) !important;
}

.fs-24 {
    font-size: var(--fs-24) !important;
}

.fs-28 {
    font-size: var(--fs-28) !important;
}

.fs-32 {
    font-size: var(--fs-32) !important;
}

.fs-42 {
    font-size: var(--fs-42) !important;
}

.fs-52 {
    font-size: var(--fs-52) !important;
}

.fs-68 {
    font-size: var(--fs-68) !important;
}

.fs-96 {
    font-size: var(--fs-96) !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

/* TEXT SOLID COLORES STYLES */
.lt-text-white-color {
    color: var(--lt-text-white-color) !important;
}

.lt-text-primary {
    color: var(--lt-text-primary) !important;
}

.lt-text-secondary {
    color: var(--lt-text-secondary) !important;
}

.lt-text-disabled {
    color: var(--lt-text-disabled) !important;
}

.lt-text-error {
    color: var(--lt-text-error) !important;
}

.lt-text-warning {
    color: var(--lt-text-warning) !important;
}

.lt-text-success {
    color: var(--lt-text-success) !important;
}

.lt-text-success-alt {
    color: var(--lt-text-success-alt) !important;
}

.lt-text-primary-alt {
    color: var(--lt-text-primary-alt) !important;
}

.lt-bg-orange {
    color: var(--lt-bg-orange) !important;
}

/*.lt-text-link-primary {
    color: var(--lt-text-link-primary) !important;
}*/

.lt-text-404 {
    color: var(--lt-text-404) !important;
}

.align-items-center {
    align-items: center;
}

/* BACKGROUND COLORES STYLES */
.btn-close:focus {
    box-shadow: none !important;
}

.lt-modal-header .btn-close {
    position: relative;
    top: -33px;
    right: -10px;
    z-index: 1;
}

.lt-modal-header > .login-close {
    top: 15px !important;
    right: 13px !important;
}

.lt-bg-primary {
    background-color: var(--lt-bg-primary);
}

.lt-bg-dark {
    background-color: var(--lt-bg-dark);
}

.lt-bg-darker {
    background-color: var(--lt-bg-darker);
}

.lt-bg-dark-alt {
    background-color: var(--lt-bg-dark-alt);
}

.lt-bg-secondary {
    background-color: var(--lt-bg-secondary);
}

.lt-bg-light {
    background-color: var(--lt-bg-light);
}

.lt-bg-lighter {
    background-color: var(--lt-bg-lighter);
}

.lt-bg-lighter-alt {
    background-color: var(--lt-bg-lighter-alt);
}

.lt-bg-white {
    background-color: var(--lt-bg-white);
}

.lt-bg-gray-10 {
    background-color: var(--lt-bg-gray-10);
}

.lt-bg-gray-150 {
    background-color: var(--lt-bg-gray-150);
}

.lt-hover-gradient-bg {
    background: var(--lt-hover-gradient-bg) !important;
}

/* SNACK LIGHT BACKGROUND COLORES STYLES */
.lt-snack-bg-error {
    background-color: var(--lt-snack-bg-error);
}

.lt-snack-bg-warning {
    background-color: var(--lt-snack-bg-warning);
}

.lt-snack-bg-success {
    background-color: var(--lt-snack-bg-success);
}

.lt-snack-bg-success-fill {
    background-color: var(--lt-snack-bg-success-fill);
}

/* ALERT SOLID BACKGROUND COLORS STYLES */

.lt-alert-bg-error {
    background-color: var(--lt-alert-bg-error);
}

.lt-alert-bg-warning {
    background-color: var(--lt-alert-bg-warning);
}

.lt-alert-bg-success {
    background-color: var(--lt-alert-bg-success);
}

.lt-alert-bg-warning-fill {
    background-color: var(--lt-alert-bg-warning-fill);
}

.lt-alert-bg-secondary-fill {
    background-color: gray;
}

/* BOX SHADOW COLOR */
.lt-shadow-xs {
    box-shadow: var(--lt-shadow-xs) !important;
}

.lt-shadow-sm {
    box-shadow: var(--lt-shadow-sm) !important;
}

.lt-shadow-md {
    box-shadow: var(--lt-shadow-md) !important;
}

.lt-shadow-lg {
    box-shadow: var(--lt-shadow-lg) !important;
}

.lt-shadow-xl {
    box-shadow: var(--lt-shadow-xl) !important;
}

.grayscale {
    -webkit-filter: grayscale(20);
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(20);
    position: relative;
}
.block-element {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #929dafbd;
    top: 0;
    left: 0;
    color: #323130;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 111;
}
.block-element > span {
    border-radius: 4px;
    background-color: var(--lt-bg-dark-alt);
    color: var(--lt-text-white-color);
    font-size: var(--fs-14);
    padding: 5px 10px;
}
.block-element:hover {
    cursor: not-allowed;
}
.lt-action-btn {
    padding: 6px 8px 5px 8px;
    font-size: var(--fs-16);
    text-align: center;
    margin: 0 2px;
}

.lt-action-btn:hover {
    padding: 6px 8px 5px 8px;
    border-radius: 4px;
    background-color: var(--lt-bg-light);
    color: var(--lt-text-link-primary) !important;
    cursor: pointer;
}

.lt-action-btn.text-black:hover {
    color: var(--lt-text-link-primary) !important;
}

.lt-action-btn > i {
    margin-right: 2px;
    margin-left: 2px;
}

.lt-del:hover {
    background-color: var(--lt-status-error);
}

.lt-dropdown-menu > button {
    background-color: transparent;
    border: 0;
    color: var(--lt-text-primary);
    padding: 0 10px;
}

.lt-dropdown-menu > button::after {
    display: none;
}

.lt-dropdown-menu .dropdown-menu > li > button {
    border-bottom: 1px solid var(--divider-default);
    padding: 7px 15px;
}

.lt-dropdown-menu .dropdown-menu > li > button:hover,
.lt-dropdown-menu .dropdown-menu > li > button:active {
    background-color: var(--lt-bg-lighter-alt);
    color: #1e2125;
}

.lt-dropdown-menu .dropdown-menu.show {
    top: 65px;
    border: 0;
    right: -3px !important;
}

.lt-dropdown-menu > a > i {
    font-size: var(--fs-20);
}

.invite-friends {
    width: 185px;
}

.lt-dropdown-menu > button:hover {
    background-color: transparent;
    color: var(--lt-text-primary-alt);
}

.lt-dropdown-menu > button:focus,
.lt-dropdown-menu > button:active {
    background-color: transparent;
    color: var(--lt-text-primary-alt);
    box-shadow: none !important;
}

.lt-dropdown-menu > a > span {
    display: block;
    font-size: var(--fs-10);
    font-weight: var(--fw-400);
}

.lt-dropdown-list {
    border: 1px solid var(--divider-default);
    padding: 5px;
}

.lt-notify-dropdown {
    width: 350px;
    left: 18px !important;
}
.notifiy-li {
    height: 450px;
    overflow-y: auto;
}
.scrolls-area {
    width: 100%;
    height: 430px;
    overflow-y: auto;
}

.lt-notify-dropdown > div > div {
    border-bottom: 1px solid var(--divider-default);
}

.lt-notify-dropdown > div > div:last-child {
    border-bottom: 0;
}

.lt-notify-height {
    height: 344px;
}

.lt-profile-dropdown > ul {
    width: 240px;
}

.lt-profile-dropdown .dropdown-menu > li > a {
    border-top: 1px solid var(--divider-default) !important;
    border-bottom: 0 !important;
}

.lt-profile-dropdown .dropdown-menu > li > button {
    border-top: 1px solid var(--divider-default) !important;
    padding: 7px 15px;
}

.notifiy-li > li:hover {
    background-color: #f3f3f3;
}

.align-center {
    display: flex;
    align-items: center;
}

.company-name {
    width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
}

.lt-star-rate {
    line-height: 1;
}

/* STATUS STYLES */
.lt-status {
    display: inline-block;
    padding: 0.4em 1em;
    margin: 0.4em 0;
    font-size: var(--fs-12) !important;
    font-weight: var(--fw-600);
}

.lt-status-primary {
    background-color: var(--lt-status-primary);
    color: var(--lt-status-text-primary);
}

.lt-status-warning {
    background-color: var(--lt-status-warning);
    color: var(--lt-status-text-warning);
}

.lt-status-success {
    background-color: var(--lt-status-success);
    color: var(--lt-status-text-success);
}

.lt-status-error {
    background-color: var(--lt-status-error);
    color: var(--lt-status-text-error);
}

.lt-status-default {
    background-color: var(--lt-status-default);
    color: var(--lt-status-text-default);
}

.sticky {
    position: sticky;
    z-index: 10;
    top: 0;
}

hr {
    background-color: var(--divider-default);
    opacity: 1;
}

/* FONT ICONS STYLES */

.bi {
    margin-right: 4px;
}

/* DRAG AND DROP */
.border-default {
    border: 1px solid var(--border-default) !important;
}

.drag-drop-box {
    border: 2px dashed var(--border-default);
    padding: 15px;
    min-height: 104px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.drag-drop-box:hover {
    background-color: var(--lt-bg-lighter-alt);
    border: 2px dashed var(--input-border-primary-alt);
    cursor: pointer;
}

.drag-drop-box > div {
    margin: auto;
}

/* MODAL POPUP */
/*.lt-modal-dialog-md {
    min-width: 600px !important;
}*/

.lt-modal-content {
    padding: 28px;
}

.lt-modal-body,
.lt-modal-header,
.lt-modal-footer {
    padding: 0 !important;
}

.lt-modal-header {
    border-bottom: 0 !important;
    text-align: center;
}

.avatar {
    width: 32px;
    height: 32px;
}

.lt-modal-footer {
    border-top: 0 !important;
}

/* NAV TABS STYLES */
.lt-nav-pills {
    margin-bottom: 15px;
}

.lt-nav-pills ul {
    border-bottom: 3px solid var(--divider-default);
}

.lt-nav-pills ul li .nav-link {
    color: var(--lt-text-disabled);
    padding: 15px 15px 10px !important;
    font-weight: 400;
}

.lt-nav-pills ul li .nav-link.active {
    position: relative;
    background-color: var(--lt-bg-white);
    border-radius: 0;
    color: var(--lt-text-primary-alt);
    font-weight: 700;
    padding: 15px 15px 10px !important;
    border-bottom: 3px solid var(--input-border-primary-alt);
    margin-bottom: -3px;
}

.position-relative {
    position: relative !important;
}

/* CARDS */
.lt-card {
    padding: var(--card-padding);
}

.lt-filter-icon {
    position: absolute;
    padding: 11px 10px;
    color: var(--lt-text-disabled);
}

.lt-clear-icon {
    position: absolute;
    padding: 11px 10px;
    color: var(--lt-text-disabled);
    right: 0;
    cursor: pointer;
}

.lt-filter-box {
    padding: 3px 30px 3px 30px;
}

/* GRID TABLES STYLES */
.p-datatable .p-datatable-thead > tr > th {
    padding: 3px 10px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 10px 15px !important;
}

.p-datatable-scrollable-header-table .p-datatable-thead th input {
    padding-left: 30px;
    border-radius: 4px;
    transition: all 0.5s ease-in-out;
    padding-right: 10px;
    max-width: 100%;
    border: 1px solid var(--border-default);
    height: 30px;
    width: 100%;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background-color: var(--lt-bg-white) !important;
    border: 0px solid var(--lt-bg-white) !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background-color: var(--lt-bg-white) !important;
}

.p-datatable .p-datatable-thead > tr > th {
    padding: 3px 5px !important;
    padding-right: 5px !important;
    color: var(--lt-text-primary) !important;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none !important;
}

.p-datatable .p-datatable-thead > tr > th input {
    padding: 6px 5px !important;
    padding-left: 30px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 10px 7px !important;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: var(--lt-bg-white) !important;
}

.p-datatable .p-datatable-tbody > tr:nth-child(odd) {
    background-color: #f9f9f9 !important;
}

.p-datatable .p-datatable-tbody > tr:hover {
    background-color: #effafc !important;
}

.p-datatable .p-datatable-tbody > tr {
    border-bottom: 0.5px solid #e6e6e6;
}

.p-datatable-scrollable-body {
    margin-top: 10px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-top: -1px;
}

.pi-sort-alt:before,
.pi-sort-amount-down:before {
    content: '\f282' !important;
    font-family: bootstrap-icons !important;
}

.pi-sort-amount-up-alt:before {
    content: '\f286' !important;
    font-family: bootstrap-icons !important;
}

.p-datatable-scrollable-header-box {
    padding-right: 0 !important;
}

.pi-sort-alt:before,
.pi-sort-amount-down:before {
    content: '\f282' !important;
    font-family: bootstrap-icons !important;
}

.pi-sort-amount-up-alt:before {
    content: '\f286' !important;
    font-family: bootstrap-icons !important;
}

.p-datatable-scrollable-header-box {
    padding-right: 0 !important;
}

.p-datatable-scrollable-body {
    overflow-x: hidden !important;
}

.p-datatable .p-datatable-tbody > tr {
    color: var(--lt-text-primary) !important;
}

.p-datatable .p-datatable-tbody .bi-check2 {
    font-size: 22px !important;
}

.g5_filter[aria-expanded='true'] .bi-chevron-down::before {
    content: '\f286';
}

.g5_filter[aria-expanded='true'] {
    background-color: var(--lt-bg-lighter-alt) !important;
}

.g5_filter[aria-expanded='false'] {
    background-color: var(--lt-bg-white) !important;
    border: 1px solid var(--divider-default);
}

/* SLIDER SWITCH STYLES */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    height: 24px;
    width: 50px;
    cursor: pointer;
}

.slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    top: 2px;
}

input:checked + .slider {
    background-color: var(--lt-bg-success);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider-name {
    left: 53px;
    position: relative;
    top: 3px;
}

.review-textarea {
    min-height: var(--review-textarea) !important;
}

/* #endregion */

/* PROFILE STYLES */
.lt-profile-banner {
    background: var(--lt-gradient-bg-blue);
    min-height: var(--profile-img-height);
}

.lt-profile-banner-hover {
    background: var(--lt-hover-gradient-bg);
}

.lt-profile-details {
    background: var(--lt-bg-white);
    min-height: var(--profile-details-height);
    padding: 20px;
}

.lt-profile-pic {
    border-radius: 50%;
    margin-top: -50px;
    width: 128px;
    height: 128px;
    border: 3px solid var(--lt-bg-white);
    border-radius: 50%;
    background: var(--lt-bg-light-blue);
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
}

.lt-profile-pic > i {
    font-size: var(--fs-68);
    align-self: center;
    margin: 0 auto;
    color: var(--lt-text-white-color);
}

.lt-profile-pic > img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.lt-profile-pic-hover {
    background: var(--lt-hover-gradient-bg);
}

.lt-profile-edit-pic > i {
    position: absolute !important;
    left: 21% !important;
    top: 8px !important;
    color: #b3a5a5;
}

.lt-profile-edit-pic > button {
    position: absolute !important;
    left: 32.2% !important;
    top: 37px !important;
}

/* TABS STYLES */
.tab-min-height {
    min-height: var(--tab-min-height);
}

.no-records-area {
    display: inline-grid;
    align-items: center;
    width: 100%;
    align-content: center;
    min-height: var(--tab-min-height);
}

.no-records-area > img {
    margin: auto;
    width: 139px;
}

.post-textarea {
    min-height: 64px;
}

.lt-tab-pane {
    padding: 15px 0;
}

.review-li {
    border-top: 1px solid var(--divider-default);
    border-bottom: 1px solid var(--divider-default);
    padding: 20px 0;
}

.post-li {
    border-top: 1px solid var(--divider-default);
    border-bottom: 1px solid var(--divider-default);
    padding: 20px 0;
}

/* TOOLTIP STYLES */
.lt-tooltip {
    position: relative;
    display: inline-block;
}

.lt-tooltip .lt-tooltiptext {
    visibility: hidden;
    min-width: 21em;
    background-color: var(--lt-bg-white);
    text-align: left;
    padding: 4px 8px;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    font-style: normal !important;
    border: 1px solid var(--divider-default);
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    right: 24px;
    color: var(--lt-text-primary);
}

.lt-tooltip:hover .lt-tooltiptext {
    visibility: visible;
    cursor: pointer;
}

.bottom-tip {
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.top-tip {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.left-tip {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.right-tip {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.last-tooltip {
    right: inherit;
    left: -170% !important;
}

/* Swalo popups */
.swal2-styled.swal2-confirm {
    background-color: var(--lt-bg-primary) !important;
    order: 1;
}

.swal2-popup .swal2-styled.swal2-confirm:hover {
    opacity: 0.8 !important;
}

.p-datatable .p-datatable-tbody > tr.g5-selected-row {
    background-color: #effafcff !important;
}

.left-notifiy-col {
    max-height: 520px;
    overflow-y: auto;
    overflow-x: hidden;
}

.filter-checked {
    border-left: 4px solid var(--lt-bg-primary) !important;
    border-right: none !important;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.filter-checked:first-child {
    border-top: none !important;
}

.login-buttons {
    margin-top: 10px;
}

.g5-filter-separator {
    position: relative;
}

.g5-filter-separator:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 80%;
    background: var(--divider-default);
    top: 10%;
    left: 0;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: var(--lt-text-primary) !important;
    font-weight: Var(--fw-700);
}

.breadcrumb li > a {
    text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '\f285' !important;
    font-family: bootstrap-icons !important;
}

.lt-pos-sticky {
    position: sticky !important;
    top: 0;
    z-index: 1;
}

/* Filter Dropdowns */
.lt-filter-drops > .p-dropdown {
    width: 100%;
    font-family: var(--pop-font-family) !important;
}

.p-dropdown:not(.p-disabled):focus {
    box-shadow: none !important;
}

.lt-filter-drops > .p-dropdown .p-dropdown-label.p-placeholder {
    padding: 4.5px 10px;
}

.lt-filter-drops > div > div > .p-dropdown-trigger-icon {
    position: relative;
    right: 0;
}

.lt-filter-drops > .p-dropdown .p-dropdown-label {
    padding: 4.5px 10px;
}

.lt-filter-drops .p-dropdown-trigger-icon {
    font-family: 'primeicons' !important;
    font-size: var(--fs-13);
    right: 0.8rem;
    position: relative;
    padding: 1px 8px;
}

.lt-filter-drops .p-dropdown .p-dropdown-clear-icon {
    right: 0.8rem !important;
    font-size: 13px;
    top: 16px;
    padding: 1px 8px;
    background-color: var(--lt-bg-white) !important;
    z-index: 1;
}

.p-multiselect-trigger-icon {
    font-size: 12px;
}

.p-component,
.p-inputtext {
    font-family: var(--pop-font-family) !important;
    font-size: 14px !important;
    font-size: 14px !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-left: 5px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: var(--lt-bg-lighter-alt);
    box-shadow: none !important;
}

.p-multiselect {
    border: 1px solid #ccc !important;
    width: 100%;
}

.p-multiselect .p-multiselect-label {
    padding: 0.3rem 0.5rem;
}

.p-multiselect-filter-container > input {
    padding: 5px !important;
    padding-left: 5px !important;
}

.p-multiselect-filter-container > input:focus {
    box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-header {
    padding-right: 0.4rem;
}

.ql-container,
.ql-editor {
    font-size: var(--fs-16) !important;
    font-family: var(--pop-font-family) !important;
}

.p-chips .p-chips-multiple-container {
    padding: 0 !important;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0px !important;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: var(--pop-font-family) !important;
    font-size: var(--fs-14) !important;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input:focus {
    box-shadow: none;
}

/* BUTTONS */
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
    box-shadow: none;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.social-btns .btn:not(.btn-lg) {
    font-size: inherit;
}

.social-btn-text {
    padding-left: 12px;
    font-size: var(--fs-14);
}

.login-btn {
    border-radius: 6px;
    font-weight: 400;
    height: 40px;
    position: relative;
    /* width: 100%; */
}

/* .login-btn > span.microsoft-icon {
    background-color: #1f5dc2;
    text-align: center;
    padding: 10px 13px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.login-btn > span.google-icon {
    background-color: #b73d2a;
    border: 1px solid #b73d2a;
    text-align: center;
    padding: 8.5px 12px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
} */

.login-btn > span.linkedin-icon {
    background-color: #216195;
    border: 1px solid #216195;
    text-align: center;
    padding: 8.5px 12px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.login-btn > span.amazon-icon {
    background-color: #232f3e;
    border: 1px solid #232f3e;
    text-align: center;
    padding: 7.8px 12px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.microsoft-btn {
    background-color: #fff;
    border-color: var(--border-default);
    color: var(--lt-text-secondary);
}

.microsoft-btn:hover {
    background-color: #f5f4f4;
    border-color: var(--border-default);
    color: var(--lt-text-secondary);
}

.microsoft-btn:hover .microsoft-icon {
    background-color: #f5f4f4;
}

.microsoft-btn:active,
.microsoft-btn:focus {
    border-color: var(--border-default) !important;
}

.google-btn {
    background-color: #df4a32;
    border-color: #df4a32;
}

.google-btn:hover {
    background-color: #d14028;
    border-color: #d14028;
}

.linkedin-btn {
    background-color: #2876b4;
    border-color: #2876b4;
    color: var(--lt-text-white-color);
}

.linkedin-btn:hover {
    background-color: #206fb1 !important;
    border-color: #206fb1;
    color: var(--lt-text-white-color);
}

.amazon-btn {
    background-color: #43474d;
    border-color: #43474d;
}

.amazon-btn:hover {
    background-color: #525860;
    border-color: #525860;
}

.signup-icon > div {
    padding: 5px 25px;
}

.divider-line {
    border-bottom: 1px solid var(--divider-default);
    width: 100%;
    margin: 55px 0;
    text-align: center;
    position: relative;
}

.divider-line > span {
    background-color: var(--lt-bg-white);
    padding: 5px 8px;
    display: block;
    font-weight: 700;
    font-size: var(--fs-14);
    position: absolute;
    left: 49%;
    top: -15px;
}

.op-card-box {
    width: 255.01px;
    height: 164px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 5px;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
}

.op-card-box > img {
    margin: auto;
}

.op-card-box:hover {
    background: #eaf6ff;
    border: 3px solid #c7e9f4;
    cursor: pointer;
}

.header-divider {
    width: 100%;
    border-right: 1px solid #ccc;
    padding: 7px 0;
}

.lt-text-area {
    height: 150px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid var(--border-default);
    width: 100%;
    padding: 15px;
}

.home-frame-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.review-textbox-h {
    height: 152px;
}

.about-textarea {
    height: 96px;
}
/* Profile page CSS STARTS */
.profile-banner-box {
    border-radius: 5px;
    border-bottom: 1px solid var(--divider-default);
}

.profile-banner {
    height: 168px;
    background: linear-gradient(
        91.31deg,
        rgba(37, 85, 207, 0.5) 0%,
        rgba(71, 43, 195, 0.5) 44.63%,
        rgba(129, 158, 229, 0.5) 100%
    );
    border-radius: 5px 5px 0 0;
}

.profile-details {
    height: 75px;
    background: var(--lt-bg-white);
    border-radius: 0 0 5px 5px;
}

.profile-page {
    padding: 2% 0;
}

.profile-pic {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    border: 4px solid var(--lt-bg-white);
    position: relative;
    top: -35px;
}

.profile-card {
    padding: 24px;
}

.box-out-pad {
    margin-top: 24px;
}

.lt-review-li {
    border-width: 1px 0;
    border-color: var(--divider-default);
    border-style: solid;
    padding: 15px 0;
}
.border-right {
    border-right: 1px solid var(--divider-default) !important;
}
/* profile page ends */

/* offer letter */
.page-layout {
    padding: 81px 61px 81px 61px;
}

.full-page-view {
    height: 1200px;
    overflow-y: auto;
}

.small-page-view {
    height: 1200px;
    overflow-y: auto;
}

.small-page-layout {
    padding: 20px 15px 20px 15px;
}

.lt-nav-tabs {
    border-bottom: 2px solid var(--divider-default);
}

.lt-offcanvas-end {
    width: 45% !important;
}

.lt-nav-bar {
    border-bottom: 3px solid var(--divider-default);
    margin-bottom: 10px;
}

.step-nav-link {
    background-color: transparent;
    border: 0;
    line-height: 6;
    padding: 0;
    font-size: var(--fs-12);
    color: #a19f9d;
}

.step-nav-link > div {
    border-radius: 50%;
    border: 2px solid var(--border-default);
    padding: 0px 6px;
    margin-right: 10px;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 2;
    font-weight: var(--fw-400);
}

.step-nav-link.active {
    font-weight: var(--fw-700);
    color: var(--lt-text-primary);
}

.step-nav-link.active > div {
    background-color: #2bc04c;
    color: var(--lt-text-white-color);
    border: 1px solid #2bc04c;
}

.step-complete > div {
    background-color: #2bc04c;
    color: var(--lt-text-white-color);
    border: 1px solid #2bc04c;
}

.step-complete > div::before {
    content: '\f272';
    font-family: 'bootstrap-icons';
    color: var(--lt-text-white-color);
}

.step-complete > div > span {
    display: none;
    line-height: 2;
}

.li-review {
    border-bottom: 1px solid var(--divider-default);
    padding: 15px 0;
}

.card-body > .li-review:last-child {
    border-bottom: 0;
}

.dropdown-box {
    position: relative;
}

.lt-search-dropdown {
    position: absolute;
    width: 100%;
    background-color: var(--lt-bg-white);
    border: 1px solid var(--divider-default);
    box-sizing: border-box;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1), 0px 3px 17px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    margin-top: 5px;
    padding: 15px;
}

.bi-circle-fill {
    top: -3px;
    position: relative;
}

.step-content-box {
    min-height: 530px;
}

.lt-icon-button {
    text-align: center;
    color: var(--lt-text-secondary);
    text-decoration: none;
}

.lt-icon-button > span {
    display: block;
}

.d-flex > .label {
    display: flex;
    align-items: center;
}

.items-selecter {
    padding: 3px 10px;
    border: 0;
    border-bottom: 1px solid #a19f9d;
    margin-left: 7px;
    outline: none;
    background-color: var(--lt-bg-white);
    color: var(--lt-text-primary);
}

.unplug-bg {
    background: url('../img/404.png') no-repeat;
    background-position: center;
}

/* Landing Home */

section {
    padding: 75px 0;
}

.plan-bg {
    background-color: var(--lt-bg-white);
}

.gray-bg {
    background-color: var(--lt-bg-gray-10);
}

.lt-blue-bg {
    background-color: var(--lt-bg-white);
}

.hero-banner {
    padding: 75px 0;
}

.why-we-built {
    padding: 75px 0;
}

.align-left-border {
    border-top: 8px solid var(--border-primary-alt);
    width: 65px;
    margin-bottom: 24px;
}

.section-50 {
    padding: 50px 0;
}

/* About-us */
.about-us h1 {
    font-size: var(--fs-42);
    font-weight: var(--fw-700);
}

.about-us h2 {
    font-size: var(--fs-32);
    font-weight: var(--fw-600);
}

.aboutus-pic img {
    width: 80%;
}

section {
    padding: 80px 0px;
}

.company-description {
    margin-top: 80px;
}

.landing-footer {
    background: var(--lt-bg-footer);
    padding: 40px 0;
    color: #eaf6ff;
}

.landing-footer h4 {
    font-size: var(--fs-18);
    font-weight: var(--fw-700);
}

.offerx-footer-address a {
    text-decoration: none;
    display: block;
    padding: 5px 0;
    color: #eaf6ff;
}

.offerx-footer-links {
    text-decoration: none;
    padding: 0;
    list-style: none;
}

.offerx-footer-links a {
    display: block;
    padding: 4px 0;
    color: #eaf6ff;
    text-decoration: none;
}

.offerx-social-icons a {
    font-size: var(--fs-32);
    margin-right: 20px;
    color: #eaf6ff;
}

.landing-footer h4 {
    margin-bottom: 24px;
}

button:focus,
button {
    outline: 0 !important;
    box-shadow: none !important;
}

.offcanvas-body .nav-link {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.notify-badge {
    top: 10px !important;
    left: 60%;
}

.profile-email {
    word-break: break-all;
}

.btn-primary {
    background-color: var(--lt-btn-primary) !important;
    border-color: var(--lt-btn-primary) !important;
}

.btn-outline-primary {
    color: var(--lt-btn-outline-primary) !important;
    border-color: var(--lt-btn-outline-primary) !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
    background-color: var(--lt-btn-outline-primary) !important;
    border-color: var(--lt-btn-outline-primary) !important;
    color: var(--lt-text-white-color) !important;
}

.btn-disabled {
    border-color: transparent !important;
    color: #a19f9d !important;
    background-color: var(--border-default) !important;
}

.lt-text-primary-default {
    color: var(--lt-text-primary-default);
}

.lt-header-line {
    width: 65px;
    height: 8px;
    background: var(--lt-text-primary-default);
    display: inline-block;
    margin: 0 0 24px 0;
}

a {
    color: var(--lt-text-primary-default);
}

.lt-dropdown-menu .dropdown-menu > li > button:hover,
.lt-dropdown-menu .dropdown-menu > li > button:active {
    background-color: var(--lt-bg-lighter-alt);
    color: #1e2125;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: var(--lt-bg-lighter-alt);
    color: #1e2125;
}

.form-section {
    border: 1px solid var(--divider-default);
    border-radius: 6px;
    padding: 14px;
    margin-bottom: 1rem;
}

.lt-bg-faq-light-alt {
    background-color: #eaf6ff;
}

.alert {
    border-radius: 6px;
}

.lt-text-dark-blue {
    color: #01487b;
}

.bulk-companies tr:hover {
    box-shadow: none;
}

.bulk-companies th {
    width: 200px;
    position: sticky;
    top: 0;
    font-size: var(--fs-14);
    z-index: 1;
    background-color: white;
}

.bulk-companies {
    height: calc(100vh - 410px);
    overflow-y: auto;
}

.form-select:disabled {
    background-color: #e9ecef !important;
}

.company-verification .offer-table-th {
    width: 160px;
}

.custom-radial-bar-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.radial-chart-svg {
    transform: rotate(0deg);
}

.legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.legend-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.legend-label {
    font-size: 14px;
}

.custom-radial-bar-chart .chart-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 3px;
    pointer-events: none;
    transform: translate(0%, 50%);
    z-index: 10;
}

@keyframes draw {
    to {
        stroke-dasharray: 100% 0;
    }
}

@media (max-width: 568px) {
    .offcanvas-end {
        width: 340px;
    }

    .section-50 {
        padding: 10px 0 !important;
    }
}

@media (max-width: 767px) {
    .about-us h1 {
        font-size: var(--fs-28) !important;
    }

    .about-us h2 {
        font-size: var(--fs-20) !important;
    }

    section.tryofferx {
        background-image: none !important;
        padding: 60px 0px !important;
    }
    .lt-tooltip .lt-tooltiptext {
        min-width: fit-content;
    }
    .mobile-changes label.fs-14 {
        font-size: var(--fs-12) !important;
    }
    .mobile-dropdown {
        transform: translate3d(0px, 38px, 0px) !important;
        left: 0 !important;
    }
    .m-w-100 {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .landing-footer h4 {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .offcanvas-end .nav-link {
        padding: 10px 0 !important;
    }

    .thumb-img {
        width: 75%;
    }

    .aboutus-pic img {
        margin-top: 30px !important;
        width: 100% !important;
    }

    .rebalancing-pic,
    .company-one {
        margin-top: 40px;
    }

    .company-description {
        margin-top: 0px;
    }
    table {
        table-layout: auto;
    }
    .lt_suspendStatus .offer-table-th {
        width: max-content;
    }
    .offer-table-th {
        width: max-content;
    }
}

@media (min-width: 1536px) {
    .company-verification .lt_name .offer-table-th {
        width: 199px;
    }
}

@media (max-width: 1536px) {
    .label-alignment .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
    .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
        display: grid !important;
    }
    .offer-table-th {
        font-size: var(--fs-12) !important;
    }
    .table-responsive td {
        font-size: var(--fs-12) !important;
    }
    .bulk-companies .offer-container {
        height: 170px !important;
    }
    .bulk-companies {
        height: calc(100vh - 370px);
    }
}

@media (max-width: 1280px) {
    .bulk-companies {
        height: calc(100vh - 380px);
    }
}

@media (min-width: 1200px) {
    .lt-contact {
        padding: 0 80px;
        border-right: 1px solid var(--border-default);
    }
}

.form-control {
    border: 1px solid var(--border-default);
}

.table-striped > tbody > tr:nth-of-type(even) {
    --bs-table-accent-bg: var(--lt-bg-gray-10);
    background: var(--lt-bg-gray-10);
}

.table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--lt-bg-white);
    background: var(--lt-bg-white);
}

.table-striped > tbody > tr td {
    border-color: var(--divider-default);
    box-shadow: none;
    vertical-align: middle;
}

.table-striped > tbody > tr td img {
    width: 48px;
    height: 48px;
}

.table > :not(:last-child) > :last-child > * {
    border-color: var(--divider-default);
}

.offcanvas-header {
    align-items: start;
}

.contact-pic img {
    width: 80%;
}

/*dark theme Css */
.navbar-light .navbar-brand,
.nav-link.text-dark,
.nav-link.text-dark .text-dark {
    color: var(--lt-text-primary) !important;
}

.bg-white,
.modal-content,
.card,
.profile-details,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.accordion-header,
.accordion-button,
.accordion-item {
    background-color: var(--lt-bg-white) !important;
}
.list-group-item {
    background-color: var(--lt-bg-white) !important;
    border-color: var(--border-default);
}

.offer-select--header .react-select__control {
    border-color: var(--border-default) !important;
    background-color: var(--lt-bg-white) !important;
}

.form-control,
.page-select .react-select__control,
.form-select,
.offer-select .react-select__control,
.react-datepicker__input-container input {
    color: var(--lt-text-primary) !important;
    background-color: var(--lt-bg-form-color) !important;
    border-color: var(--border-default) !important;
}

.navbar-light .navbar-toggler,
.dropdown-item {
    color: var(--lt-text-primary) !important;
}

.navbar-light .navbar-toggler {
    background-color: var(--lt-bg-lighter);
}

.dropdown-menu {
    background-color: var(--lt-bg-white) !important;
    color: var(--lt-text-primary) !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: var(--lt-bg-lighter-alt) !important;
    color: var(--lt-text-primary) !important;
}

.offcanvas.show {
    background-color: var(--lt-bg-gray-10) !important;
    visibility: visible;
}

.react-select__control .react-select__single-value {
    color: var(--lt-text-primary) !important;
}

table {
    color: var(--lt-text-primary) !important;
}

table td,
.lt-action-btn.text-black,
.react-datepicker__day {
    color: var(--lt-text-dark-theme) !important;
}

.react-datepicker,
.react-datepicker__header {
    background-color: var(--lt-bg-white) !important;
    color: var(--lt-text-primary) !important;
}

.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: var(--lt-bg-lighter-alt) !important;
}

.react-datepicker__week .react-datepicker__day--range-start,
.react-datepicker__week .react-datepicker__day--range-end,
.react-datepicker__week .react-datepicker__day--selecting-range-start,
.react-datepicker__week .react-datepicker__day--selecting-range-end {
    background-color: var(--lt-bg-primary) !important;
}

/* .react-datepicker__day--in-range:hover,
.react-datepicker__day:hover {
    background-color: var(--lt-bg-footer) !important;
} */

.react-datepicker-header__btn {
    background-color: var(--lt-bg-white) !important;
}

.react-datepicker__day--disabled {
    color: var(--lt-text-secondary) !important;
}

.offer-select .react-select__input-container {
    color: var(--lt-text-primary) !important;
}

.react-datepicker-header--range {
    border-color: var(--divider-default) !important;
}

.offer-select .react-select__option,
.react-select__menu div {
    background-color: var(--lt-bg-white) !important;
    border-color: var(--border-default) !important;
}

.react-select-menu-btn {
    background-color: var(--lt-bg-white) !important;
}

.offer-select .react-select__option:focus,
.offer-select .react-select__option:hover .offer-select .react-select__option:focus div,
.offer-select .react-select__option:hover div {
    background-color: var(--lt-bg-lighter-alt) !important;
}

.offer-select .react-select__option:focus-visible,
.offer-select .react-select__option:focus-within,
.offer-select .react-select__option:target,
.offer-select .react-select__option:visited,
.offer-select .react-select__option:active {
    background-color: var(--lt-bg-gray-10) !important;
}

.react-select__menu .react-select__option {
    background-color: var(--lt-bg-white) !important;
}

.react-select__menu .react-select__option:focus,
.react-select__menu .react-select__option:hover {
    background-color: var(--lt-bg-lighter-alt) !important;
}

.react-select__menu .react-select__option:focus-visible,
.react-select__menu .react-select__option:active,
.react-select__menu .react-select__option:focus-visible div,
.react-select__menu .react-select__option:active div {
    background-color: var(--lt-bg-primary) !important;
}

.btn-close {
    background: none;
}

.btn-close:before {
    content: '\F659';
    font-family: 'bootstrap-icons';
    color: var(--lt-text-primary);
    font-size: var(--fs-18);
}

.react-datepicker__input-container {
    position: relative;
}

.react-datepicker__input-container::before {
    content: '\F214';
    font-family: 'bootstrap-icons';
    color: var(--lt-text-primary);
    position: absolute;
    top: 5px;
    right: 10px;
}

.react-datepicker__input-container input {
    background-image: none !important;
}

.react-datepicker__input-container input::before {
    content: '\F1F6';
    font-family: 'bootstrap-icons';
    color: var(--lt-text-primary);
    font-size: var(--fs-18);
}

.btn-link {
    color: var(--lt-text-primary-default);
}

.lt-profile-dropdown .border-bottom {
    border-color: var(--divider-default) !important;
}

.card {
    box-shadow: none;
    border: 0px;
}

@media (min-width: 992px) {
    .lt-modal-dialog-md {
        min-width: 600px !important;
    }
    .desktop-hide {
        display: none;
    }
}

#chartContainer2 {
    min-height: 220px !important;
}

.lt-review-dot {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 3px;
}

.lt-table-border.table > :not(caption) > * > * {
    border-color: #ececec;
    padding: 0.85em 1.5em;
}

.lt-progress-height-5 {
    height: 5px;
    display: none;
}

.lt-nav-bar2 .nav-tabs .nav-link {
    border: 1px solid #ececec;
    border-radius: 4px;
    margin: 2px;
}

.lt-nav-bar2 .nav-tabs .nav-link.active {
    border-color: var(--lt-text-primary-alt);
}

.lt-step-number {
    width: 2.2em;
    height: 2.2em;
    display: block;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    line-height: 2.2em;
    text-align: center;
    color: #fff;
}

.lt-time-line {
    position: relative;
}

.lt-time-line:before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: 20px;
    z-index: 1;
    display: inline-block;
    border-left: 2px dotted #d2d2d2;
}

.lt-time-line li {
    position: relative;
    margin-bottom: 20px;
    z-index: 9;
}

.row-deck > .col,
.row-deck > [class*='col-'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.row-deck .card {
    width: 100%;
}

.lt-social-card-header i {
    font-size: var(--fs-32);
}

.lt-social-card-header {
    padding: 9px;
    background-repeat: inherit;
    background-image: url(../img/bg.png);
    position: relative;
    background-size: cover;
}

.lt-social-card-header::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
}

.lt-social-card-header i {
    position: relative;
    z-index: 9;
}

.lt-facebook-header:before {
    background-color: rgb(66 103 178 / 70%);
}

.lt-instagram-header:before {
    background-color: rgb(193 53 132 / 70%);
}

.lt-linkedin-header:before {
    background-color: rgb(0 119 181 / 70%);
}

.lt-twitter-header:before {
    background-color: rgb(29 161 242 / 70%);
}

.lt-icon-circle {
    width: 2.2em;
    height: 2.2em;
    display: block;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    line-height: 2.2em;
    text-align: center;
    color: var(--lt-text-white-color);
}

.lt-alert-bg-green {
    background-color: var(--lt-bg-success);
}

.lt-text-green {
    color: var(--lt-bg-success) !important;
}
.lt-bg-alert-darker {
    background-color: var(--lt-bg-alert-darker);
}
.lt-data-absolute {
    position: absolute;
    z-index: 99;
    width: 110%;
    background: var(--lt-text-white-color);
    padding: 20px;
    left: -1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    top: 35px;
}
.lt-shape-card {
    position: relative;
    border: 1px solid var(--divider-default) !important;
}
.lt-shape-card:before {
    content: '';
    position: absolute;
    width: 55px;
    height: 55px;
    border-radius: 0px 0 0 50px;
    background: var(--lt-text-primary-alt);
    right: 0;
    top: -1px;
    color: var(--lt-bg-lighter);
    opacity: 0.08;
}

.lt-dashboard-icon {
    position: absolute;
    right: 7px;
    top: 4px;
}

.lt-nav-bar .nav-link {
    cursor: pointer;
}
.offer-table-th {
    font-size: var(--fs-14);
}
.lt-usermanagement-table tr th:last-child .offer-table-th {
    text-align: end;
}
.border,
.border-start {
    border-color: var(--divider-default) !important;
}
.apexcharts-toolbar {
    z-index: 0 !important;
}
.offerx_left_nav {
    padding: 13px;
    width: 15em;
}
.page-content {
    padding: 1em 0 0 15em;
}
.sidebar-ms-4 {
    margin-left: 1.5rem;
}
@media (max-width: 1366px) {
    .offerx_left_nav {
        width: 13em;
    }
    .page-content {
        padding: 1em 0 0 13em;
    }
    .sidebar-ms-4 {
        margin-left: 0.5rem !important;
    }
    .offerx_nav_links .nav-link {
        font-size: var(--fs-10);
    }
    .company-page-container {
        padding: 0 0px;
    }
    .small-mt-0 {
        margin-top: 0 !important;
    }
    .company-verification .lt_suspendStatus .offer-table-th {
        width: auto;
    }
    .bulk-companies .offer-container {
        height: auto !important;
    }
}
@media (max-width: 1440px) {
    .lt-star-review-card .bi {
        font-size: 12px;
        margin-right: 2px;
    }

    .lt-star-review-card .fs-16 {
        font-size: var(--fs-14) !important;
    }

    .lt-score-review-text {
        display: none;
    }
}
@media (min-width: 1366px) and (max-width: 1440px) {
    .row-xl-deck > .col,
    .row-xl-deck > [class*='col-'] {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .lt-shape-card:before {
        width: 58px;
        height: 58px;
    }
    .lt_suspensionDecision {
        width: 100px;
    }
    .lt_location {
        width: 200px;
    }
    .lt_name .offer-table-th {
        width: 139px;
    }
}

.w-160 {
    width: 160px;
}

.lt-bg-alert-darker {
    background-color: var(--lt-bg-alert-darker);
}

.lt-today-date {
    padding: 14px 24px;
    background-color: rgba(0, 150, 255, 0.08);
    border-radius: 0.5em;
}

.lt-login {
    background: rgb(203, 234, 255) no-repeat;
    background: linear-gradient(0deg, rgba(203, 234, 255, 1) 19%, rgba(255, 255, 255, 1) 100%);
    background-position: 50%;
    background-size: cover;
    min-height: 100vh;
    display: flex;
}

.table-hover > tbody > tr.nodataRow:hover > * {
    background-color: #fff !important;
}

.table-hover > tbody > tr.nodataRow:hover {
    box-shadow: none !important;
}

.table-hover > tbody > tr.nodataRow:hover td::after {
    display: none !important;
}

.table-hover > tbody > tr.nodataRow .offer-container {
    height: 280px;
}
.read {
    opacity: 0.5;
    cursor: default;
}
.cursor-pointer {
    cursor: pointer;
}
.text-justify {
    text-align: justify;
}
.accordion-button:not(.collapsed) {
    color: #323130;
}
#ReportReasons .accordion-button {
    font-size: 14px;
}
.lt-modal-header .btn-close {
    position: relative;
    top: -20px;
    right: -10px;
    z-index: 1;
}
.employer-verification .btn-close {
    top: -50px;
}
.employer-table tr th:last-child .offer-table-th {
    text-align: center;
}
.report-table tr th:last-child .offer-table-th {
    text-align: center;
}
.comp-reg-table tr th:last-child .offer-table-th {
    text-align: center;
}
.action-modal .react-pdf__Document {
    max-height: 546px !important;
}
.text-crop {
    position: relative;
    width: 100%;
    height: 100%;
}
.text-crop .big-text {
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}
.action-btn {
    width: 100px;
}
.float-right {
    float: right;
}
.action-modal .action-img {
    height: calc(100vh - 400px);
    overflow: auto;
}
/* .lt_suspendStatus .offer-table-th {
    text-align: center !important;
} */
.uploaded-div .uploaded-border {
    background-color: #f9f9f9;
    padding: 0.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
}
.outline-p-hover:hover {
    background-color: #f5f4f4 !important;
    color: var(--lt-text-primary-default) !important;
}
/* toggle switch starts */
input#toggle-btn {
    display: none;
}
.toggle-switch label {
    position: relative;
    cursor: pointer;
    display: block;
    width: 45px;
    height: 24px;
    background: #cccccc;
    border-radius: 50px;
    transition: 300ms linear;
}

.toggle-switch label:before {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
    transition: left 300ms linear;
}

.toggle-switch input:checked + label {
    background: var(--lt-text-primary-alt);
}

.toggle-switch input:checked + label:before {
    left: 23px;
}
/* toggle switch ends */
.lt-text-chocolate {
    color: #c5b000;
}
.btn-outline-brown {
    background-color: transparent;
    border: 1px solid #c5b000;
    color: #c5b000;
}

.btn-outline-brown:hover {
    background-color: #c5b000;
    border: 1px solid #c5b000;
    color: #fff;
}

.btn-outline-brown.disabled,
.btn-outline-brown:disabled {
    opacity: 0.45;
    cursor: not-allowed;
}
.lt_salesOwner .offer-table-th {
    width: max-content;
}

.form-control {
    border-radius: 6px;
}

.form-select {
    min-height: calc(1.5em + 0.5rem + 3px);
}

.offer-table-scroll table tr:hover {
    box-shadow: none;
}

.offer-table-scroll .table-hover > tbody > tr:hover > * {
    background-color: transparent !important;
}

.offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 300px);
}

.legal-doc-table .offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 170px);
}

.complist-table .offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 350px);
}

.candlist-table .offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 490px);
}

.complist-table .offer-table-container .offer-table-scroll table thead tr th,
.candlist-table .offer-table-container .offer-table-scroll table thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.reported-companies .offer-table-container,
.reported-candidates .offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 330px);
}

.lt-usermanagement-table .offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 170px);
}

.vl-middle td {
    vertical-align: middle;
}

.screenX-table .offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 170px);
}

.country-dropdown .dropdown-item {
    padding: 0.5rem 1rem;
}

.country-dropdown .sticky-search {
    position: sticky;
    top: 0;
    background-color: var(--lt-bg-white);
    padding: 10px 10px;
}

.country-dropdown {
    background-color: var(--lt-bg-white);
    position: absolute;
    z-index: 16;
    border: 1px solid var(--border-default);
    max-height: calc(100vh - 430px);
    overflow-y: auto;
    bottom: auto;
    transform: translateY(0);
    transition: transform 0.2s ease-in-out;
}

.country-dropdown.up {
    bottom: auto;
    transform: translateY(-112%);
}

.w-90 {
    /* width: 100px; */
    max-width: fit-content;
}

.w-330 {
    width: 330px;
}

.modal-country .country-dropdown {
    position: fixed;
}

.legal-doc table tbody tr:hover {
    box-shadow: none;
}

.legal-doc table tbody tr td {
    font-size: var(--fs-14);
}

.legal-doc table thead tr {
    height: inherit !important;
}

.legal-doc table thead tr th {
    height: inherit !important;
}

.countrylist-height {
    max-height: 400px;
    overflow: auto;
}

.policyDoc-height {
    height: 300px;
    overflow: auto;
}

.policyDoc-height-onLoad {
    height: 550px;
    overflow: auto;
}
.view-doc-height .react-pdf__Document {
    max-height: calc(100vh - 300px);
}

.lt_uploadedBy {
    width: 200px;
}

.lt_uploadedDate {
    width: 150px;
}

.lt_dateInvited {
    width: 150px;
}

.lt_onboardedDate {
    width: 160px;
}

.comp-reg-table .lt_contactNumber {
    width: 160px;
}

.comp-reg-table .lt_leadStatus {
    width: 160px;
}

.disable-dropzone {
    opacity: 0.5;
    pointer-events: none;
}

.lt_companySuspendReasonText button i,
.lt_suspensionDecision button i,
.lt_salesOwnerName button i,
.lt_leadStatus button i,
.lt_companyInviteType button i,
.lt_salesOwner button i,
.lt_suspendStatus button i,
.lt_salesOwner button i,
.lt_candidateSuspendReasonText button i {
    display: none;
}

.skeleton-loader {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    -webkit-animation: shine 1s ease infinite;
    animation: shine 1s ease infinite;
    width: 12rem;
    height: 1rem;
}

.sticky-left {
    position: sticky;
    left: 0;
}

@-webkit-keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

@media (min-width: 1280px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 420px);
    }
}
@media (min-width: 1536px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 530px);
    }
}
@media (min-width: 1745px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 630px);
    }
}
@media (min-width: 1920px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 720px);
    }
}
@media (min-width: 2133px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 900px);
    }
}
@media (min-width: 2400px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 1030px);
    }
}
@media (min-width: 2560px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 1110px);
    }
}
@media (min-width: 3072px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 1650px);
    }
}

@media (min-width: 3480px) {
    .modal-country .country-dropdown {
        max-height: calc(100vh - 1750px);
    }
}

.link-button {
    width: 100% !important;
    text-align: left !important;
}

.admin-company-list-modal > div.modal-dialog,
.admin-candidate-list-modal > div.modal-dialog {
    width: 80% !important;
    max-width: 80% !important;
}

.round-timer {
    position: relative;
    width: 100px;
    height: 100px;
}

.round-timer .circle {
    fill: rgb(241, 241, 241);
    stroke: #3498db;
    stroke-width: 4;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.timer-value {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #3498db;
    font-weight: bold;
}

.timer-loader {
    position: fixed;
    background-color: #ffffffc8;
    width: 100%;
    height: 100%;
    z-index: 12;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    left: 0px;
    /* transform: translate(-11%, 0); */
}

.number-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: -webkit-max-content;
    width: max-content;
}
.form-phone-border {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.invite-modal {
    max-height: calc(100vh - 230px);
    overflow-y: auto;
}

.p-fixed {
    position: fixed;
}

.p-absolute {
    position: absolute;
}

.new-spinner {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #ffffffa8;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-spinner div {
    position: relative;
}

.custom-spinner-border {
    border: 4px solid var(--border-primary-alt);
    border-right-color: transparent;
    position: absolute;
    width: 3rem;
    height: 3rem;
    margin-top: -25px;
}

.custom-loading-text {
    position: relative;
    top: 40px;
    right: 4px;
}

.comp-reg-table .offer-table-container {
    height: calc(100vh - 240px);
    overflow-y: auto !important;
}

.comp-reg-table .offer-table-container table th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.custom-line-graph .line-chart {
    width: 100% !important;
}

.chart-container {
    height: 250px;
    position: relative;
}

.custom-radial-bar-chart .radial-chart-svg {
    transform: rotate(-135deg);
}

.hrms-table .offer-table-container {
    height: calc(100vh - 130px);
}

.hrms-table .offer-table-container .offer-table-th {
    width: 220px;
}

.hrms-table .offer-table-container .lt_hrmsJoinStatus .offer-table-th {
    width: 110px;
}

.candidate-invite-table .offer-table-container {
    height: calc(100vh - 170px);
}

.candidate-invite-table tr th:last-child .offer-table-th {
    text-align: start;
}

.reported-candidates tr th:last-child {
    text-align: center;
    min-width: 100px;
}

.rewards-table .offer-table-container {
    overflow-y: auto !important;
    height: calc(100vh - 330px);
}

.lt_companyAvailableCredits .offer-table-th {
    text-align: start !important;
}

.match-cards {
    border: 1px solid var(--lt-text-primary-alt);
    padding: 10px;
    border-radius: 10px;
}
