.offer-table-container
  position: relative
  overflow: hidden


.offer-table-scroll
  position: relative
  overflow: auto
  height: 100%


.offer-table-controls
  display: flex
  align-items: center
  justify-content: space-between
  padding: 16px 0

  &__pagination
    display: flex


.offer-table
  min-width: 100%
  border-collapse: collapse
  table-layout: auto

  tbody
    border-top: none !important


.offer-table-th
  height: 100%
  font-weight: 600


.absolute-link
  content: ''
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1


.absolute-area-link
  &::before
    content: ''
    position: absolute
    top: 0
    right: 88px
    bottom: 0
    left: 0

  &--candidate
    &::before
      right: 56px


.table-btn-disabled
  pointer-events: none
  cursor: default
  opacity: 0.5


.table-review-btn-disabled
  cursor: default
  opacity: 0.5


.offer-table-link
  position: relative
  z-index: 1


.offer-table-reoffered
  position: relative
  display: inline-block
  vertical-align: middle

  &__icon
    position: absolute
    z-index: 1
    right: -8px
    bottom: -4px
    cursor: pointer
