.release-offer-container
  position: relative
  padding-bottom: 58px


.release-offer-controls
  position: absolute
  top: -58px
  right: 0


.release-offer-controls-bottom
  position: absolute
  bottom: -58px
  right: 0
